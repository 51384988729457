import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import axios from '../../services/ImroomConfig';
import './Home.scss'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor-v2';
import netflix from '../../images/netflix.jpg';
import HomeIcon from '@mui/icons-material/Home';
import PublicIcon from '@mui/icons-material/Public';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ScoreIcon from '@mui/icons-material/Score';
import creditCard from '../../images/cb.jpg';
import price from '../../images/price_opa.jpg';
import parking from '../../images/parking_opa.jpg';
import garantie from '../../images/garantie_opa.jpg';
import contrat from '../../images/facture.jpg';
import airbnb from '../../images/airbnb.jpg';
import menage from '../../images/menage.jpg';
import facture from '../../images/facture.jpg';
import { withTranslation } from 'react-i18next';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class Home extends Component {

    carouselUrl = process.env.REACT_APP_IMROOM_URL
    constructor(props) {
        super()

        this.state = {
            hoverImageParking: false,
            hoverImageGarantie: false,
            items: []
        }
    }

    componentDidMount() {
        axios.request({method: 'get', url: 'carousel.json'}).then(response => {
            let json = JSON.parse(JSON.stringify(response.data))
            this.setState({
                items: json
            })
        });
    }

    overImage = (name) => {
        this.setState({[name]: true})
    }
    leaveImage = (name) => {
        this.setState({[name]: false})
    }

    render() {
        const { t } = this.props;
        return (
            <div className="home">
                <Helmet>
                    <title>{t("Home")}</title>
                </Helmet>

                <div className="top">
                    <div className="wave_block">
                        <div className="ocean">
                            <div className="wave"></div>
                            <div className="wave"></div>
                        </div>
                    </div>
                    <div className="brands">

                        <h2>Rouen</h2>
                        {t("Equipped and Furnished Short Term Apartments")}
                        <br/>
                        <div className="separator"/>
                        <Button variant="contained" color="secondary" size="large" href="/properties">
                            {t("Book")}
                        </Button>
                        &nbsp;
                        <Button target="_blank" variant="contained" color="secondary" size="large" href="https://my-booking.info/login">
                            {t("My Book")}
                        </Button>

                    </div>
                </div>

                <div className="container-fluid-max">

                    <div className="home-part">
                        <div className="little_word">
                            <h1>Appart'HomeCity</h1>
                            <div className="description">{t("Equipped and furnished short term apartments specially designed for professionals, tourists and athletes. Don't wait any longer, come and discover the apartment that suits you for your stay.")}</div>
                        </div>

                        <Grid className="hexa" container alignItems="center" justifyContent="center" spacing={2}>
                            <ul id="grid">
                                <li onMouseOver={() => this.overImage('hoverImageParking')} onMouseOut={() => this.leaveImage('hoverImageParking')}>
                                    <div className="hexagon">
                                        {this.state.hoverImageParking === true ? (
                                            <div className="text">{t("Free private and secure parking space (exterior or interior) at each apartment")}</div>
                                        ):(
                                            <div className="titre">{t("Parking")}</div>
                                        )}
                                        <img src={parking} alt="parking"/>
                                    </div>
                                </li>
                                <li onMouseOver={() => this.overImage('hoverImageCb')} onMouseOut={() => this.leaveImage('hoverImageCb')}>
                                    <div className="hexagon">
                                        {this.state.hoverImageCb === true ? (
                                            <div className="text">{t("Secure platform for reservation payment")}</div>
                                        ):(
                                            <div className="titre">{t("Credit Card")}</div>
                                        )}
                                        <img src={creditCard} alt="creditCard"/>
                                    </div>
                                </li>
                                <li onMouseOver={() => this.overImage('hoverImageFacture')} onMouseOut={() => this.leaveImage('hoverImageFacture')}>
                                    <div className="hexagon">
                                        {this.state.hoverImageFacture === true ? (
                                            <div className="text">{t("Invoice stay sent automatically the day before check-out")}</div>
                                        ):(
                                            <div className="titre">{t("Invoice")}</div>
                                        )}
                                        <img src={facture} alt="facture"/>
                                    </div>
                                </li>
                                <li onMouseOver={() => this.overImage('hoverImagePrice')} onMouseOut={() => this.leaveImage('hoverImagePrice')}>
                                    <div className="hexagon">
                                        {this.state.hoverImagePrice === true ? (
                                            <div className="text">{t("Reduced prices directly on our internal booking platform")}</div>
                                        ):(
                                            <div className="titre">{t("Reduced price")}</div>
                                        )}
                                        <img src={price} alt="price"/>
                                    </div>
                                </li>
                                <li onMouseOver={() => this.overImage('hoverImageClean')} onMouseOut={() => this.leaveImage('hoverImageClean')}>
                                    <div className="hexagon">
                                        {this.state.hoverImageClean === true ? (
                                            <div className="text">{t("Professional concierge")} : {t("Check-in performed physically on site")}</div>
                                        ):(
                                            <div className="titre">{t("Concierge")}</div>
                                        )}
                                        <img src={menage} alt="menage"/>
                                    </div>
                                </li>
                                <li onMouseOver={() => this.overImage('hoverImageNetflix')} onMouseOut={() => this.leaveImage('hoverImageNetflix')}>
                                    <div className="hexagon">
                                        {this.state.hoverImageNetflix === true ? (
                                            <div className="text">{t("Free NETFLIX platform on our TVs")}</div>
                                        ):(
                                            <div className="titre">Netflix</div>
                                        )}
                                        <img src={netflix} alt="netflix"/>
                                    </div>
                                </li>
                                <li onMouseOver={() => this.overImage('hoverImageGarantie')} onMouseOut={() => this.leaveImage('hoverImageGarantie')}>
                                    <div className="hexagon">
                                        {this.state.hoverImageGarantie === true ? (
                                            <div className="text">{t("Security deposit by bank imprint or provisional bank collection according to the duration of the stay")}</div>
                                        ):(
                                            <div className="titre">{t("Security deposit")}</div>
                                        )}
                                        <img src={garantie} alt="garantie"/>
                                    </div>
                                </li>
                                <li onMouseOver={() => this.overImage('hoverImageContrat')} onMouseOut={() => this.leaveImage('hoverImageContrat')}>
                                    <div className="hexagon">
                                        {this.state.hoverImageContrat === true ? (
                                            <div className="text">{t("Establishment of a seasonal rental contract online")}</div>
                                        ):(
                                            <div className="titre">{t("Rental contract")}</div>
                                        )}
                                        <img src={contrat} alt="contrat"/>
                                    </div>
                                </li>
                                <li onMouseOver={() => this.overImage('hoverImageAirBnb')} onMouseOut={() => this.leaveImage('hoverImageAirBnb')}>
                                    <div className="hexagon">
                                        {this.state.hoverImageAirBnb === true ? (
                                            <div className="text">{t("Also visible on Airbnb/Booking/Abritel platforms")}</div>
                                        ):(
                                            <div className="titre">{t("Also on")}</div>
                                        )}
                                        <img src={airbnb} alt="airbnb"/>
                                    </div>
                                </li>
                            </ul>
                        </Grid>
                    </div>
                    <Grid className="numbers" container alignItems="center" justifyContent="space-around">
                        <Grid item>
                            <HomeIcon/>
                            <div className="number">
                                <CountUp
                                    end={9}
                                    duration={5}
                                >
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </div>
                            <div className="desciption">{t("Apartments")}</div>
                        </Grid>
                        <Grid item>
                            <PublicIcon/>
                            <div className="number">
                                <CountUp
                                    end={637}
                                    duration={5}
                                >
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </div>
                            <div className="desciption">{t("Travelers received")}</div>
                        </Grid>
                        <Grid item>
                            <EmojiPeopleIcon/>
                            <div className="number">
                                <CountUp
                                    end={11}
                                    duration={5}
                                >
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </div>
                            <div className="desciption">{t("Nationalities welcomed")}</div>
                        </Grid>
                        <Grid item>
                            <ScoreIcon/>
                            <div className="number">
                                <CountUp
                                    end={8.2}
                                    decimals={1}
                                    duration={5}
                                    suffix="/10"
                                >
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>
                            </div>
                            <div className="desciption">{t("Average rating for travelers")}</div>
                        </Grid>
                    </Grid>
                    <Grid className="carousel_bloc" container alignItems="center" justifyContent="center">
                        <Grid item style={{'width':'80%'}}>
                            <Carousel
                                showArrows={true}
                                autoPlay
                                showIndicators={false}
                                showStatus={false}
                                infiniteLoop={true}
                            >
                                { this.state.items.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <img src={this.carouselUrl + item.image} alt=""/>
                                            <p className="legend">{item.name}</p>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </Grid>
                    </Grid>
                    <Grid className="btn-book" container alignItems="center" justifyContent="center">
                        <Button variant="contained" color="secondary" size="large" href="/properties">
                            {t("See our offers")}
                        </Button>
                    </Grid>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Home);